<template>
    <div>
        <v-tabs 
            v-model="mainTab" 
            :value="selected" 
            centered 
            icons-and-text>
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#tabOpera" >
                Operaciones
                <v-icon>mdi-view-dashboard</v-icon>
            </v-tab>            
            <v-tab href="#tabRepo" >
                Reportes
                <v-icon>mdi-download</v-icon>
            </v-tab>
        </v-tabs>
        <v-tabs-items 
            v-model="mainTab" 
            :value="selected">
            <v-tab-item value="tabOpera" >                                                    
                <v-row ref="idItemOpera">  
                    <template v-for="(element, id) in menus">
                        <template v-if="element.type === 0" >                                            
                            <list-menu 
                                :key="id" 
                                :data="element">
                            </list-menu>
                        </template>
                    </template>
                </v-row>
            </v-tab-item>            
            <v-tab-item value="tabRepo">                
                <v-row>
                    <template v-for="(element, id) in menus">
                        <template v-if="element.type === 1" >                                            
                            <list-menu 
                                :key="id" 
                                :data="element">
                            </list-menu>
                        </template>
                    </template>
                </v-row>
            </v-tab-item>            
        </v-tabs-items>
    </div>
</template>

<script>    
    import { mapGetters } from 'vuex'
    import ListMenu from './HomeCardListMenu'
    export default {                
        name : "HomeCard",
        data : () => ({
            mainTab  : null,
            selected : 1
        }), 
        components:{
            ListMenu
        },
        computed : {
            ...mapGetters('home',{menus : 'getElementsById'}),
            seenOpera(){
                return this.menus.some(el => el.type == 0 )             
            },
            seenRepo(){
                return this.menus.some(el => el.type == 1 )             
            }
        },        
        props : {
            data : {
                type : Object
            }
        }
    }
</script>
