<template>
    <v-col 
        xs="12" 
        sm="12" 
        md="4">
        <v-card 
            class="mx-auto" 
            max-width="400" 
            tile>                                        
            <v-list flat>
                <v-subheader>{{data.name}}</v-subheader>
                <v-list-item-group>
                    <v-list-item 
                        v-for="(concepto, id ) in data.conceptos" 
                        :key="id" 
                        @click.stop="showConcepto(concepto)">                            
                        <v-list-item-content>                                        
                            {{concepto.name}}
                        </v-list-item-content>
                        <v-list-item-icon 
                            v-for="(icon, id) in concepto.format" 
                            :key="id">
                            <v-icon :color="icons[icon].color">{{icons[icon].icon}}</v-icon>                            
                        </v-list-item-icon>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-card>  
    </v-col>  
</template>
<script>
    import { mapActions } from 'vuex'
    export default {
        data: () => ({
            icons : {
                "excel" : {
                    color : "green",
                    icon  : "mdi-file-excel"
                },
                "pdf" : {
                    color : "red",
                    icon  : "mdi-file-pdf"
                },
                "xml" : {
                    color : "blue",
                    icon  : "mdi-xml"
                },
                "concepto" : {
                    color : "indigo",

                    icon  : "mdi-arrow-right-circle"
                }
            }
        }),
        props : {
            data : Object
        },
        methods:{
            ...mapActions("home",["setComponent", "setDialog"]),
            ...mapActions("auth",["setConcepto"]),
            showConcepto : function( { format, link } ){
                if( format.includes("concepto") ){ // ABRIRA EL DASH DE CONCEPTO                     
                    this.$router.push(link)
                }else{
                    this.setConcepto(link)
                    this.setComponent(link); // ABRIRA SU MODAL
                    this.setDialog(true)
                }                
            }
        }
    }
</script>